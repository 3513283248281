<template>
  <div class="home index-page">
    <AppNavBar />
    <div class="homeContent index-page" id="indexContent">

      <div class="carousel carousel-slider">
        <a v-for="(image, index) in images" :key="index" class="carousel-item" :href="`#${image.id}`">
          <img :src="getImage(image.src)" :alt="image.alt" loading="lazy" />
        </a>
        <div class="header-text text-center center-align white-text" id="indexHeaderText">
          <h4 class="text-center center-align">
            PLATINUM MINDS GLOBAL COMPANY<wbr />
          </h4>
        </div>
      </div>

      <div class="white-text darken-3">
        <div class="row">
          <div class="row">
            <div class="container">
              <div class="card-panel blue-grey darken-4 white-text z-depth-4 animated fadeIn">
                <h4 class="center-align white-text">Our Vision</h4>
                <div class="divider"></div>
                <div class="row">
                  <div class="col s12 m6 l6">
                    <div class="card blue lighten-5 z-depth-3 animated bounceIn">
                      <div class="card-content center-align">
                        <i class="icon icon ion-university x3 blue-text text-darken-1"></i>
                        <span class="card-title blue-text text-darken-1">Education & Knowledge</span>
                        <p class="black-text">
                          Rejuvenating interest in teaching, learning, and applying academic knowledge to real-life problems.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col s12 m6 l6">
                    <div class="card green lighten-5 z-depth-3 animated flash">
                      <div class="card-content center-align">
                        <i class="icon ion-leaf green-text text-darken-3 x3" />
                        <span class="card-title green-text text-darken-3">Environmental Sustainability</span>
                        <p class="black-text">
                          Transforming waste into sustainable energy solutions: biogas, syngas, biochar, crude oil, and electricity.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col s12 m6 l6">
                    <div class="card cyan lighten-5 z-depth-3 animated fadeInRight">
                      <div class="card-content center-align">
                        <i class="icon ion-hammer cyan-text x3"></i>
                        <span class="card-title cyan-text">Technology & Innovation</span>
                        <p class="black-text">
                          Creating high-tech products to revolutionize Africa’s economy.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col s12 m6 l6">
                    <div class="card lime lighten-5 z-depth-3 animated fadeIn">
                      <div class="card-content center-align">
                        <i class="icon ion-ios-nutrition green-text text-darken-3 x3"></i>
                        <span class="card-title green-text text-darken-3">Agricultural Enhancement</span>
                        <p class="black-text">
                          Adding value to agricultural products by producing healthy and organic goods.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m8 offset-m2 animated bounceInUp">
              <div class="card">
                <div class="card-image">
                  <router-link to="PRC2025" class="">
                    <img :src="require('@/assets/prc2025_1.jpg')" />
                  </router-link>
                </div>
                <div class="card-content black-text">
                  <span class="btn-small grey lighten-3 black-text">
                    Platinum Reasoning Contest 2025
                  </span>
                  <br/><br/>
                  <p class="bold">
                    All schools are expected to carefully read the instructions in the link below before proceeding with student(s) registration.
                  </p>
                </div>
                <div class="container countDown">
                  <h5 class="blue-text">Countdown to June 30, 2025</h5>
                  <h6 class="red-text">{{ countdown }}</h6>
                </div>
                <div class="card-action">
                  <!-- <a href="https://forms.gle/2jtnMS4YCpuwTrzV6" target="_blank" class="btn red darken-3">Register</a> 
                   -->
                   <router-link to="/PRC2025" class="btn red white-text animated tada infinite">Learn more to register...</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          <!-- Members of the team -->
    <div class="row">
      <br><br>
      <h3 class="text-center center-align" id="teamMembers"><i class="icon ion-android-contacts"></i> &nbsp;Our Team
        <!-- <router-link to="/members-survey"><i class="icon ion-compose btn grey tooltipped"  title="Fill member survey"></i></router-link> -->
      </h3>
      <br/><br/>
      <div class="col s12 m6 l3 offset-m3 offset-l1">
        <div class="card">
          <div class="card-image">
            <img :src="require('@/assets/members/makinde.jpg')" class="responsive-img" style="height: 307px">
            <span class="card-title">Prof. Deborah Makinde</span>
          </div>
          <div class="card-content">
            <span class="black-text bold" style="font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif">
            <!-- Head of Educational <wbr/>Services and consultancy/Shareholder -->
            Director of Education/SH
            </span><br/><br/>
            <p>Accomplished educationist & complex analyst, with extensive teaching experience across all educational levels, currently serving as a Professor at Obafemi Awolowo University, where she continues to inspire and educate future generations.</p>
          </div>
        </div>
      </div>
      <div class="col s12 m6 l3 offset-m3">
        <div class="card">
          <div class="card-image">
            <img :src="require('@/assets/members/jaiyeola.jpg')" class="responsive-img" style="height: 307px"/>
            <span class="card-title">Prof. Temitope Jaiyeola</span>
          </div>
          <div class="card-content">
            <span class="black-text bold" style="font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif">
            <!-- Head of Human <wbr/>Resources Management -->
            Director of Human Resources/SH
            </span><br/><br/>
            <p>
              A renowned Mathematician and academic leader, bringing analytical expertise and strategic optimization skills to HR management driving informed decision-making and growth. Proven track record of achievement, with prestigious awards and publications.
            </p>
          </div>
        </div>
      </div>
      <div class="col s12 m6 l3 offset-m3">
        <div class="card">
          <div class="card-image">
            <img :src="require('@/assets/members/president22.jpg')" class="responsive-img" style="height: 307px"/>
            <span class="card-title">Mr. Abdulkadri G. Agboola</span>
          </div>
          <div class="card-content">
            <span class="black-text bold" style="font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif">CEO<wbr/></span><br/><br/>
            <p>Renewable Energy Expert | Business Strategist | Coach Dedicated to sustainable development, empowering youths, and driving positive change. Proven expertise in renewable energy, business growth, and social responsibility.</p>
          </div>
        </div>
         <router-link to="/members" class="btn red white-text right">More...</router-link>
      </div>
    </div>
    </div>
    <FooterPage />
    <router-view></router-view>
  </div>
</template>

<script>
import M from 'materialize-css'
import AppNavBar from '@/components/layouts/NavBar.vue'
import FooterPage from '@/components/layouts/footer-page.vue'

export default {
  name: 'Home',
  components: {
    AppNavBar,
    FooterPage
  },
  data() {
    return {
      carousel: null,
      images: [
        { id: "one", src: "pic1.jpg", alt: "Historic Event 1" },
        { id: "two", src: "pic2.jpg", alt: "Historic Event 2" },
        { id: "three", src: "pic3.jpg", alt: "Historic Event 3" },
        { id: "four", src: "pic5.jpg", alt: "Historic Event 5" },
        { id: "five", src: "pic6.jpg", alt: "Historic Event 6" },
        { id: "six", src: "pic7.jpg", alt: "Historic Event 7" },
        { id: "seven", src: "pic8.jpg", alt: "Historic Event 8" },
        { id: "eight", src: "pic9.png", alt: "Historic Event 9" },
        { id: "nine", src: "pic11.jpg", alt: "Historic Event 11" },
        { id: "ten", src: "pic12.jpg", alt: "Historic Event 12" },
        { id: "eleven", src: "pic13.jpg", alt: "Historic Event 13" },
        { id: "twelve", src: "pic14.jpg", alt: "Historic Event 14" },
        { id: "thirteen", src: "pic15.jpg", alt: "Historic Event 15" },
        { id: "fourteen", src: "pic16.jpg", alt: "Historic Event 16" }
      ],
      countdown: '',
      targetDate: new Date('2025-06-30T00:00:00').getTime()
    }
  },
  metaInfo() {
    return {
      title: 'Platinum Minds Global Company - Home',
      meta: [
        { name: 'description', content: 'Learn about our vision in education, technology, environmental sustainability, and agriculture.' },
        { name: 'keywords', content: 'education, sustainability, technology, agriculture, innovation' }
      ]
    }
  },
  methods: {
    getImage(fileName) {
      return require(`@/assets/PRC_History/${fileName}`);
    },
    updateCountdown() {
      const now = new Date().getTime();
      const timeLeft = this.targetDate - now;
                    
        if (timeLeft <= 0) {
          this.countdown = "Time's up!";
          return;
        }
                    
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
                    
      this.countdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }
  },
  mounted() {
    // Initialize Materialize Carousel
    const elem = document.querySelector('.carousel')
    if (elem) {
      this.carousel = M.Carousel.init(elem, {
        fullWidth: true,
        indicators: true
      })

      // Auto-slide every 6.5 seconds
      setInterval(() => {
        if (this.carousel) {
          this.carousel.next()
        }
      }, 6500)
    }
    this.updateCountdown()
    setInterval(this.updateCountdown, 1000)
  }
}
</script>

<style scoped>
/* Default height for large screens (Laptops, Desktops) */
.carousel .carousel-item img {
  height: 750px; /* Large screen height */
  object-fit: cover; /* Ensures the image scales properly */
  width: 100%;
}



.carousel-container {
  position: relative;
  width: 100%;
  height: auto;
}

div.header-text.text-center.center-align.white-text h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
}
.achievement-card {
  padding: 20px;
  margin: 20px auto;
  border: 2px solid #ffffff; /* White border */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2); /* Soft glow effect */
  max-width: 500px; /* Restrict width for readability */
  background-color: rgba(255, 255, 255, 0.1); /* Light transparent background */
}

.achievement-title {
  display: block;
  width: 100%;
  text-align: center;
  padding: 12px;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 5px;
}

.achievement-list {
  padding-left: 0;
  margin-top: 15px;
  list-style-type: none;
}

.achievement-list li {
  background: rgba(255, 255, 255, 0.2); /* Light grey background */
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  border-left: 5px solid #ffcc00; /* Golden left border */
  transition: transform 0.3s ease, background 0.3s ease;
}

.achievement-list li:hover {
  background: rgba(255, 255, 255, 0.3); /* Lighter on hover */
  transform: scale(1.02);
}
#indexContent > div.white-text.darken-3 > div > div:nth-child(1) > div > div.card-image > img {
    height: 530px;
}
.achievement-list a {
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  display: block;
  text-align: center;
  padding: 10px;
  transition: color 0.3s;
}

.achievement-list a:hover {
  color: #ffcc00;
}
#indexContent > div.white-text.darken-3 > div > div:nth-child(1) > div > div.card-image > img {
  height: 530px;
  width: 100%;
  object-fit: cover;
}

#visionContainer  div.card-panel {
  padding: 20px;
  border-radius: 10px;
}
#visionContainer div.card {
  border-radius: 10px;
}
#visionContainer div.card-content {
  padding: 20px;
  display: block;
  margin-bottom: 10px;
}

/* Medium screens (Tablets, Small Laptops) */
@media screen and (max-width: 1024px) {
  #indexContent > div.white-text.darken-3 > div > div:nth-child(1) > div > div.card-image > img {
    height: 400px;
  }
}

/* Small screens (Mobile Phones) */
@media screen and (max-width: 768px) {
  #indexContent > div.white-text.darken-3 > div > div:nth-child(1) > div > div.card-image > img {
    height: 300px;
  }
}

/* Extra small screens (very small mobile devices) */
@media screen and (max-width: 480px) {
  #indexContent > div.white-text.darken-3 > div > div:nth-child(1) > div > div.card-image > img {
    height: 250px;
  }
}

/* ✅ Responsive Design */
@media screen and (max-width: 768px) {
  .achievement-card {
    padding: 15px;
    margin: 10px auto;
    max-width: 90%;
  }

  .achievement-title {
    font-size: 1.3rem;
    padding: 10px;
  }

  .achievement-list li {
    padding: 12px;
    margin: 8px 0;
  }

  .achievement-list a {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 480px) {
  .achievement-card {
    padding: 12px;
    max-width: 100%;
  }

  .achievement-title {
    font-size: 1.2rem;
    padding: 8px;
  }

  .achievement-list li {
    padding: 10px;
    margin: 6px 0;
  }

  .achievement-list a {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 600px) {
   #indexContent > div.header-text.center-align.white-text > h4 {
      font-size: 1.4rem !important;
    }
}

/* Medium screens (Tablets, Small Laptops) */
@media screen and (max-width: 1024px) {
  .carousel .carousel-item img {
    height: 600px; /* Reduce height */
  }
}

/* Small screens (Mobile Phones) */
@media screen and (max-width: 768px) {
  .carousel .carousel-item img {
    height: 450px; /* Further reduce height */
  }
}
</style>
