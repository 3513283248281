import activeModules from '@/views/activeModules'
import ErrPage from '@/components/layouts/pageNotFound.vue'
import Index from './indexPage.vue'
import About from './aboutPage.vue'
import ContactUs from './contactPage.vue'
import Programs from './programs.vue'
import PRC from './prc2025.vue'


let routes = [
  {
    path: '/',
    name: 'Platinum Minds Global Company',
    component: Index,
  },
  {
    path: '/about',
    name: 'About Platinum Minds Global Company',
    component: About,
  },
  {
    path: '/contact',
    name: 'PMGC || Contact us',
    component: ContactUs,
  },
  {
    path: '/programs',
    name: 'PMGC | Our programs',
    component: Programs,
  },
  {
    path: '/PRC2025',
    name: 'PMGC | Platinum-Reasoning-Contest-2025',
    component: PRC,
  },
  {
    path: '/:catchAll(.*)', // This is the new catch-all route
    name: 'Error 404',
    component: ErrPage,
  },
]

activeModules.forEach((modul) => {
  try {
    // get module routes
    let moduleRoutes = require('@/views/' + modul + '/router').default
    // add the routes gotten to the route array
    // console.log({moduleRoutes})
    routes = routes.concat(moduleRoutes)
    // console.log({ routes })
  } catch (e) {
    // console.log(e)
  }
})
export default routes
