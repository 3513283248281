import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/views/router'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

let fetchStoreData = (key) => {
  if (
    window.localStorage.getItem('PMGC-default') &&
    window.localStorage.getItem('PMGC-default') !== null
  ) {
    return JSON.parse(
      JSON.parse(window.localStorage.getItem('PMGC-default')).value
    )[key]
  } else {
    return null
  }
}
let updateStore = (key, value) => {
  let storeData = {}
  if (window.localStorage.getItem('PMGC-default')) {
    storeData = JSON.parse(
      JSON.parse(window.localStorage.getItem('PMGC-default')).value
    )
  }
  storeData[key] = value
  localStorage.setItem(
    'PMGC-default',
    JSON.stringify({ value: JSON.stringify(storeData) })
  )
}

router.beforeEach((to, from, next) => {
  // console.log({to, from, next})
  window.document.title = to.name
  // console.log({fetchStoreData: fetchStoreData(`token`), fetchStoreDataU: fetchStoreData(`user`)})
  let userType = fetchStoreData('user')
  if (!to.meta.requiresAuth) next()
  else if (to.meta.requiresAuth) {
    console.log('i am running before each routes')
    if (to.meta.candidateAuth) {
      if (!fetchStoreData('token') || userType !== 'candidate') {
        updateStore('lastPageVisited', to.path)
        alert('YOU ARE NOT AUTHORIZED TO ACCESS THIS PAGE!')
        // Window.alert('NOT AUTHORIZED!')
        next('/login')
      } else next()
    }
  }
})

export default router
