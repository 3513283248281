import { createStore } from 'vuex' // Use createStore for Vue 3
import createPersist from 'vuex-localstorage' // Ensure this plugin is compatible

export default createStore({
  strict: true,
  state: {
    token: null,
    user: null,
    userData: {},
    questionLang: null,
    currentGallery: null,
    currentMember: null,
    candidateData: {},
  },
  getters: {},
  mutations: {
    SET_ADMINDATA(state, user) {
      state.token = user.token
      state.userData.fullName = user.fullName
      state.userData.email = user.email
      state.user = user.user
    },
    SET_CANDIDATEDATA(state, user) {
      state.user = user.user
      state.token = user.token
      state.address = user.address
      state.candidateClass = user.candidateClass
      state.email = user.email
      state.fullName = user.fullName
      state.guardianEmail = user.guardianEmail
      state.guardianName = user.guardianName
      state.guardianPhoneNo = user.guardianPhoneNo
      state.programCategory = user.programCategory
      state.school = user.school
      state.state = user.state
      state.region = user.region
      state.userName = user.userName
      state.date = new Date(user.date).toDateString()
    },
    SET_CANDIDATEPIN(state, user) {
      state.candidateData = user
    },
    SET_PAYMENTDATA(state, user) {
      state.message = user.message
      state.transaction = user.transaction
      state.reference = user.reference
      state.status = user.status
    },
    SET_PAID(state) {
      state.candidateData.paid = true
    },
    SET_EXERCISE_LANGUAGE(state, data) {
      state.questionLang = data.questionLang
    },
    CLEAR_CANDIDATE(state) {
      state.userData = {}
      state.token = null
      state.user = null
      state.candidateData = null
      state.currentGallery = null
      state.currentMember = null
    },
    CLEAR_ALL(state) {
      state.userData = {}
      state.token = null
      state.user = null
      state.currentGallery = null
      state.currentMember = null
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersist({
      namespace: 'PMGC',
      expires: 10 * 60 * 1e3, // Expires in 10 minutes.
    }),
  ],
})
