// import components for members

import Index from './index'
// import Survey from './survey'

export default [
  {
    path: '/members',
    name: 'Platinum Minds Global Company Members',
    component: Index
  }
  // {
  //   path: `/members-survey`,
  //   name: 'PMGC Member Survey',
  //   component: Survey
  // }
]
