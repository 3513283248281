<!-- <template>
    <div class="pmgc-programs">
        <navbar />
        <div class="container">
            <div class="card-panel blue-grey darken-4 white-text z-depth-4 animated fadeIn">
            <h4 class="center-align white-text">PMGC Programs</h4>
            <div class="divider"></div>
            
            <div class="row">
                <div class="col s12 m6 l4" v-for="(program, index) in programs" :key="index">
                <div class="card hoverable z-depth-3">
                    <div class="card-image">
                    <img v-for="(img, imgIndex) in program.images" :key="imgIndex" :src="img" alt="Program Image">
                    <span class="card-title">{{ program.title }}</span>
                    </div>
                    <div class="card-content">
                    <p>{{ program.description }}</p>
                    </div>
                </div>
                </div>
            </div>
            
            <h5 class="center-align white-text">What We Have Done</h5>
            <div class="divider"></div>
            <div class="row">
                <div class="col s12 m6 l4" v-for="(achievement, index) in achievements" :key="index">
                <div class="card hoverable z-depth-3">
                    <div class="card-image">
                    <div class="carousel">
                        <a v-for="(img, imgIndex) in achievement.images" :key="imgIndex" class="carousel-item">
                        <img :src="img" alt="Achievement Image">
                        </a>
                    </div>
                    <span class="card-title">{{ achievement.title }}</span>
                    </div>
                    <div class="card-content">
                    <p>{{ achievement.description }}</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    <footer-page />
    <router-view></router-view>
    </div>
</template>

<script>
import Navbar from '@/components/layouts/NavBar.vue'
import FooterPage from '@/components/layouts/footer-page'
import M from "materialize-css";

export default {
  components: { Navbar, FooterPage },
  mounted() {
    this.$nextTick(() => {
      M.Carousel.init(document.querySelectorAll('.carousel'));
    });
  },
  data() {
    return {
        programs: [
        {
            title: "Platinum Reasoning Contest",
            images: [
            require('@/assets/programs/program_prc1.png'),
            require('@/assets/programs/program_prc2.png'),
            require('@/assets/programs/program_prc1.png')
            ],
            description: "A prestigious competition that sharpens students' logical and analytical reasoning skills."
        },
        {
            title: "Biogas Project & Production of Pure Organic Fertilizer",
            images: [
            require('@/assets/programs/program_biogas5-main.jpeg'),
            require('@/assets/programs/biogas-gen1.jpeg'),
            require('@/assets/programs/biogas-gen2.jpeg'),
            
            ],
            description: "Innovative projects focused on renewable energy and waste-to-energy conversion."
        },
        {
            title: "Renewable Energy Project (Biogas)",
            images: [
            require('@/assets/programs/biogas4.jpeg'),
            require('@/assets/programs/biogas1.jpeg'),
            require('@/assets/programs/biogas2.jpeg')
            ],
            description: "Innovative projects focused on renewable energy and waste-to-energy conversion."
        }
        ],
        achievements: [
        {
            title: "BIOGAS at Redemption Camp",
            images: [
            require('@/assets/programs/biogas1.jpeg'),
            require('@/assets/programs/biogas2.jpeg'),
            require('@/assets/programs/biogas4.jpeg'),
            ],
            description: "Successful deployment of a biogas system at Redemption Camp, promoting sustainable energy."
        },
        {
            title: "Platinum Reasoning Contest Activities",
            images: [
            require('@/assets/programs/prcAct2.jpg'),
            require('@/assets/programs/prcAct3.jpg'),
            require('@/assets/programs/prcAct4.jpg'),
            require('@/assets/programs/prcAct5.jpg'),
            require('@/assets/programs/prcAct6.jpg')
            ],
            description: "A platform that nurtures critical thinking and academic excellence among students."
        },
        {
            title: "Biodegradable Septic in Abuja",
            images: [
            require('@/assets/programs/biogas3.jpeg'),
            require('@/assets/programs/program_prc2.png'),
            ],
            description: "A groundbreaking approach to waste management using biodegradable technology in Abuja."
        }
        ]
    };
    }
}
</script>


<style scoped>
.card-panel {
  padding: 20px;
  border-radius: 10px;
}
.card {
  border-radius: 10px;
}
.card-content {
  padding: 15px;
}
.card-image img {
  height: 200px;
  object-fit: cover;
  display: block;
  margin-bottom: 5px;
}
.card-title {
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 5px;
}
.carousel img {
  height: 200px;
  object-fit: cover;
}
</style> -->


<template>
  <div class="pmgc-programs">
      <navbar />
      <div class="container">
          <div class="card-panel blue-grey darken-4 white-text z-depth-4 animated fadeIn">
              <h4 class="center-align white-text">PMGC Programs</h4>
              <div class="divider"></div>
              
              <div class="row">
                  <div class="col s12 m6 l4" v-for="(program, index) in programs" :key="index">
                      <div class="card hoverable z-depth-3">
                          <div class="card-image">
                              <img v-for="(img, imgIndex) in program.images" :key="imgIndex" :data-src="img" class="lazyload responsive-img materialboxed" alt="Program Image">
                              <span class="card-title">{{ program.title }}</span>
                          </div>
                          <div class="card-content">
                              <p>{{ program.description }}</p>
                          </div>
                      </div>
                  </div>
              </div>
              
              <h5 class="center-align white-text">What We Have Done</h5>
              <div class="divider"></div>
              <div class="row">
                  <div class="col s12 m6 l4" v-for="(achievement, index) in achievements" :key="index">
                      <div class="card hoverable z-depth-3">
                          <div class="card-image">
                              <div class="carousel carousel-slider">
                                  <a v-for="(img, imgIndex) in achievement.images" :key="imgIndex" class="carousel-item">
                                      <img :data-src="img" class="lazyload responsive-img materialboxed" alt="Achievement Image">
                                  </a>
                              </div>
                              <span class="card-title">{{ achievement.title }}</span>
                          </div>
                          <div class="card-content">
                              <p>{{ achievement.description }}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <footer-page />
      <router-view></router-view>
  </div>
</template>

<script>
import Navbar from '@/components/layouts/NavBar.vue'
import FooterPage from '@/components/layouts/footer-page'
import M from "materialize-css";
import 'lazysizes';

export default {
components: { Navbar, FooterPage },
mounted() {
  this.$nextTick(() => {
    M.Carousel.init(document.querySelectorAll('.carousel'), { fullWidth: true, indicators: true });
    M.Materialbox.init(document.querySelectorAll('.materialboxed'));
  });
},
data() {
  return {
      programs: [
      {
          title: "Platinum Reasoning Contest",
          images: [
          require('@/assets/programs/program_prc1.png'),
          require('@/assets/programs/program_prc2.png'),
          require('@/assets/programs/program_prc1.png')
          ],
          description: "A prestigious competition that sharpens students' logical and analytical reasoning skills."
      },
      {
          title: "Biogas Project & Production of Pure Organic Fertilizer",
          images: [
          require('@/assets/programs/program_biogas5-main.jpeg'),
          require('@/assets/programs/biogas-gen1.jpeg'),
          require('@/assets/programs/biogas-gen2.jpeg')
          ],
          description: "Innovative projects focused on renewable energy and waste-to-energy conversion."
      },
      {
          title: "Renewable Energy Project (Biogas)",
          images: [
          require('@/assets/programs/biogas4.jpeg'),
          require('@/assets/programs/biogas1.jpeg'),
          require('@/assets/programs/biogas2.jpeg')
          ],
          description: "Innovative projects focused on renewable energy and waste-to-energy conversion."
      }
      ],
      achievements: [
      {
          title: "BIOGAS at Redemption Camp",
          images: [
          require('@/assets/programs/biogas1.jpeg'),
          require('@/assets/programs/biogas2.jpeg'),
          require('@/assets/programs/biogas4.jpeg')
          ],
          description: "Successful deployment of a biogas system at Redemption Camp, promoting sustainable energy."
      },
      {
          title: "Platinum Reasoning Contest Activities",
          images: [
          require('@/assets/programs/prcAct2.jpg'),
          require('@/assets/programs/prcAct3.jpg'),
          require('@/assets/programs/prcAct4.jpg'),
          require('@/assets/programs/prcAct5.jpg'),
          require('@/assets/programs/prcAct6.jpg')
          ],
          description: "A platform that nurtures critical thinking and academic excellence among students."
      },
      {
          title: "Biodegradable Septic in Abuja",
          images: [
          require('@/assets/programs/biogas3.jpeg'),
          require('@/assets/programs/program_prc2.png')
          ],
          description: "A groundbreaking approach to waste management using biodegradable technology in Abuja."
      }
      ]
  };
}
}
</script>

<style scoped>
.card-panel {
padding: 20px;
border-radius: 10px;
}
.card {
border-radius: 10px;
}
.card-content {
padding: 15px;
}
.card-image img {
height: 200px;
object-fit: cover;
display: block;
width: 100%;
}
.card-title {
background: rgba(0, 0, 0, 0.6);
padding: 10px;
border-radius: 5px;
color: white;
}
.carousel.carousel-slider {
height: 250px;
}
.carousel img {
height: 250px;
object-fit: cover;
width: 100%;
}
</style>
