<template>
  <div class="contact-page">
    <app-nav-bar />
    <div class="animated bounceInDown">
      <img
       :src="require('@/assets/slides/sean-kong-491810-unsplash.jpg')"
        alt="aboutbackground"
        class="responsive-img"
        width="100%"
      />
      <div class="header-text text-center center-align">
        <h4
          class="center-align white-text animated slideInLeft"
          style="
            background-color: rgba(0, 0, 0, 0.4);
            text-transform: uppercase;
          "
        >
          Contact Us!<br /><br />
          PLATINUM MINDS GLOBAL COMPANY
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="location-on-google-map col s12 m7">
        <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d31527.217789086713!2d7.379037128795373!3d8.981144437743604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sNo%207%20%2C%20MBP%20Hotel%2C%20close%20to%20UBA%20bank%20%2C%20Lugbe%20Abuja.!5e0!3m2!1sen!2sng!4v1741851190794!5m2!1sen!2sng" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <p
          class="x1 center-align text-center black-text"
          style="font-weight: bold"
        >
          If you got any questions or feedback, please do not hesitate to send
          us a message.
        </p>
        <div class="getInTouch white grey-text text-darken-3">
          <h3 class="x25 text-center center-align">Get in touch with us</h3>
          <div class="divider"></div>
          <div class="row">
            <form class="col s12">
              <div class="row">
                <div class="input-field col s6">
                  <input id="input_text" type="text" data-length="10" />
                  <label for="input_text">Name(required)</label>
                </div>
                <div class="input-field col s6">
                  <input id="input_text" type="text" data-length="10" />
                  <label for="input_text">Email(required)</label>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s6">
                  <input id="input_text" type="text" data-length="10" />
                  <label for="input_text">Subject</label>
                </div>
                <div class="input-field col s6">
                  <input id="input_text" type="text" data-length="10" />
                  <label for="input_text">Your message</label>
                </div>
              </div>
              <button
                type="submit"
                class="btn blue waves-effect right waves-orange"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="col s12 m5 x15 grey-text text-darken-3">
        <p class="animated lightSpeedIn white">
          <span class="x15">Have any questions?</span><br /><br />
          <small class="mailIcon" style=""
            ><i class="icon ion-ios-email x2" style="font-style: normal"></i
          ></small>
          &nbsp;Email: <a href="mailto:info@pmg-c.org">info@pmg-c.org</a><wbr />
          <br/>
          <i class="icon ion-ios-email x2" style="font-style: normal"></i>
          &nbsp;Email: <a href="mailto:platinumminds1@gmail.com">Platinumminds1@gmail.com</a>
        </p>
        <!-- <br /> -->
        <div class="divider"></div>
        <p class="animated lightSpeedIn white">
          <span class="x15">Support Us</span><br /><br />
          <i class="icon ion-ios-email x2" style="font-style: normal"></i>
          &nbsp;Email: <a href="mailto:support@pmg-c.org">support@pmg-c.org</a
          >
          
          <!-- <wbr /> -->
        </p>
        <!-- <br /> -->
        <!-- <br /> -->
        <div class="divider"></div>
        <p class="animated bounceIn white">
          <span class="x15">Telephone</span><br /><br />
          <i class="icon ion-ios-telephone"></i> &nbsp; Call us on<br />
          +2348160342073,<br />+2348060392066
        </p>
        <!-- <br /> -->
        <div class="divider"></div>
        <p class="animated tada white">
          <span class="x15">Meet Us</span><br /><br />
          Head Office: No 7 , MBP Hotel, close to UBA bank , Lugbe Abuja.
        </p>
      </div>
    </div>
    <footer-page />
    <router-view></router-view>
  </div>
</template>
<script>
import AppNavBar from '@/components/layouts/NavBar'
import FooterPage from '@/components/layouts/footer-page'
export default {
  components: { AppNavBar, FooterPage },
}
</script>

<style>
div.contact-page div.location-on-google-map.col.s12.m7 > div {
  padding: 2.1rem 1.5rem;
  border-radius: 20px;
}
div.contact-page div.col.s12.m5.x15.grey-text.text-darken-3 > p {
  padding: 1.5rem;
  border-radius: 20px;
}
input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:active:not([readonly]),
input:not([type]):active:not([readonly]),
input[type='text']:not(.browser-default):active:not([readonly]),
input[type='password']:not(.browser-default):active:not([readonly]),
input[type='email']:not(.browser-default):active:not([readonly]),
input[type='url']:not(.browser-default):active:not([readonly]),
input[type='time']:not(.browser-default):active:not([readonly]),
input[type='date']:not(.browser-default):active:not([readonly]),
input[type='datetime']:not(.browser-default):active:not([readonly]),
input[type='datetime-local']:not(.browser-default):active:not([readonly]),
input[type='tel']:not(.browser-default):active:not([readonly]),
input[type='number']:not(.browser-default):active:not([readonly]),
input[type='search']:not(.browser-default):active:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #2691f3;
  box-shadow: 0 1px 0 0 #2691f3;
}
input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly])
  + label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #2691f3;
}
div.contact-page > div.animated.bounceInDown > div > h4,
div.pmgc_news > div.animated.bounceInDown > div > h4 {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  padding: 1rem;
  top: 36% !important;
  left: 0px;
  right: 0px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
