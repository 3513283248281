<template>
  <div class="footer-pmgc">
    <footer class="page-footer grey darken-4">
      <div class="center-align">
        <div class="row">
          <div class="col l4 m6x">
            <h5 class="white-text animated bounceInDown">
              Have any questions?
            </h5>
            <p
              class="grey-text text-lighten-4 left animated bonceInRight"
              style="text-align: left"
            >
              We reply all questions within
            </p>
            <h2 class="blue-text col s5 animated bounceInLeft">
              48 h<br />
              <span class="text-center center-align">...</span>
            </h2>
          </div>
          <div class="mission col m6 l4">
            <h4>Our Vision</h4>
            <p class="left animated bounceIn" style="text-align: left">
              Our vision is to revitalize education, drive environmental sustainability, and foster technological innovation. We aim to transform waste into sustainable energy, create cutting-edge products to boost Africa's economy, and enhance agriculture by producing healthy, organic goods, ultimately inspiring a brighter future.
            </p>
            <p class="left animated bounce" style="text-align: left">
              <small class="mailIcon" style="position: absolute; top: -1em"
                ><i
                  class="icon ion-ios-email x2"
                  style="font-style: normal"
                ></i></small
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="mailText x1">
                Email:</span
              >
              &nbsp;<a href="mailto:info@pmg-c.org">info@pmg-c.org</a>
            </p>
          </div>
          <div class="col l4 m6">
            <h4 class="white-text animated zoomIn">Links</h4>
            <ul class="animated slideInRight">
              <li>
                <a
                  href="https://www.facebook.com/Platinummathsteam/"
                  class="grey-text text-lighten-3"
                >
                  <i class="icon ion-social-facebook x2"></i>
                  <div
                    class="fb-like"
                    data-href="https://www.facebook.com/Platinummathsteam/"
                    data-width="20px"
                    data-layout="button"
                    data-action="like"
                    data-size="large"
                    data-show-faces="true"
                    data-share="false"
                  ></div>
                </a>
              </li>
              <li>
                <a class="grey-text text-lighten-3" href="#!">
                  <i class="icon ion-social-googleplus x2"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/maths_team?ref_src=twsrc%5Etfw"
                  class="grey-text text-lighten-3 twitter-follow-button"
                  data-show-count="false"
                >
                  <i class="icon ion-social-twitter x2"></i>
                </a>
              </li>
              <li>
                <a class="grey-text text-lighten-3" href="#!">
                  <i class="icon ion-social-instagram x2"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="" style="padding: 1rem">
        <h5 class="white-text animated zoomIn">Share</h5>
        <div
          class="fb-share-button col s1"
          data-href="https://www.pmg-c.org"
          data-layout="button"
          data-size="large"
          data-mobile-iframe="true"
        >
          <a
            target="_blank"
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.facebook.com%2FPlatinummathsteam%2F&amp;src=sdkpreparse"
            class="fb-xfbml-parse-ignore"
          ></a>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a
          href="http://twitter.com/share?text=Platinum%20Minds%20Global%20Company,%20a%20registered%20non-governmental%20organization%20with%20the%20aim%20of%20rejuvenating%20the%20dwindling%20interest%20in%20....&url=https://www.pmg-c.org"
          ><i class="icon ion-social-twitter white-text x2"></i
        ></a>
        &nbsp;&nbsp;
        <a
          class="grey-text text-lighten-3"
          target="_blank"
          href="https://www.linkedin.com/shareArticle?mini=true&url=https://pmg-c.org&title=PLATINUM%20MINDS%20GLOBAL%20COMPANY&summary=Platinum%20Minds%20Global%20Company%20(PMGC)%20is%20a%20team%20that%20comprises%20of%20graduates,%20students%20and%20lecturers%20from%20different%20tertiary%20institutions%20in%20Nigeria.%20PMGC%20is%20a%20registered%20non-governmental%20organization%20with%20the%20aim%20of%20rejuvenating%20the%20dwindling%20interest%20in%20the%20teaching,%20learning%20and%20application%20of%20academic%27s%20knowledge%20acquired%20to%20real%20-life%20situations%20and%20increasing%20the%20level%20at%20which%20students%20reason.&source=https://pmg-c.org"
        >
          <i class="icon ion-social-linkedin x2"></i>
        </a>
      </div>
      <div class="footer-copyright white black-text">
        <div class="container">
          &copy; <span class="footer-year">{{ currentYear }}</span> Copyright
          All rights reserved<br
            class="show-on-small-only hide-on-med-and-up"
          />
          <a
            class="blue-grey-text text-darken-4 transparent right waves-effect waves-ripple"
            href="mailto: 24hours.i.tech@gmail.com"
            >Developed by 24Hours IT limited.</a
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: '',
    }
  },
  mounted() {
    this.currentYear = new Date().getFullYear()
  },
  ready() {
    console.log(document.querySelector('#u_0_2 > div > span._49vh._2pi7'))
    alert(document.querySelector('#u_0_2 > div > span._49vh._2pi7'))
  },
}
</script>

<style></style>
