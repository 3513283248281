<template>
  <div class="about-pmgc">
    <navbar />
    <div class="animated bounceInDown">
      <img
        :src="require('@/assets/slides/sean-kong-491810-unsplash.jpg')"
        alt="aboutbackground"
        class="responsive-img"
        width="100%"
      />
    </div>
    <div class="header-text center-align">
      <h4 class="white-text">About Us</h4>
    </div>

    <div class="about-content row" id="about-content">
      <div class="card-image" >
        <h3 class="header center-align">Platinum Minds Global Company</h3>
      </div>
      <div class="col s12 m8 offset-m2">
        <div class="card-panel grey-text text-darken-3">
          <span>
            <p class="animated fadeIn">
              Platinum Minds Global Company (PMGC) is a registered company with Corporate Affairs Commission with ( RC:2570143) , the company comprises of most intellectual group of successful people from different sectors globally.

            </p>
          </span>
          <p>
            Our aim is to add values in the following sectors:
          </p>
          <ul class="collection">
              <li class="collection-item blue darken-3 blue-text text-lighten-5">
                <i class="ion-ios-book-outline left"></i> Education
              </li>
              <li class="collection-item green darken-4 white-text text-lighten-5">
                <i class="ion-ios-sunny-outline left"></i> Renewable Energy
              </li>
              <li class="collection-item purple darken-2 white-text text-darken-1">
                <i class="ion-ios-gear-outline left"></i> Technology
              </li>
              <li class="collection-item brown darken-1 deep-purple-text text-lighten-5">
                <i class="icon ion-ios-nutrition left"></i> Agriculture
              </li>
            </ul>
        </div>
      </div>
    </div>
    <!-- Our Mission -->
    <div class="ourMission-content row">
      <div class="col s12">
        <h3 class="header center-align">Our Mission</h3>
      </div>
      <div class="col s12 m10 offset-m1">
        <ul class="collection">
          <li class="collection-item avatar">
            <i class="ion-leaf circle green"></i>
            <span class="title">Environmental Sustainability</span>
            <p>Cleaner environment through the use of greenhouse gases and non-degradable waste as sources of electricity and fuel.</p>
          </li>
          <li class="collection-item avatar">
            <i class="ion-ios-gear-outline circle blue"></i>
            <span class="title">Technological Innovation</span>
            <p>Revolutionizing human challenges with smart technology solutions.</p>
          </li>
          <li class="collection-item avatar">
            <i class="ion-ios-people-outline circle red"></i>
            <span class="title">Educational Support</span>
            <p>Appreciating and nurturing talented students.</p>
          </li>
          <li class="collection-item avatar">
            <i class="ion-ios-nutrition-outline circle brown"></i>
            <span class="title">Agricultural Advancement</span>
            <p>Promoting longevity through value-added chains in the agricultural sector.</p>
          </li>
        </ul>
      </div>
    </div>



    <footer-page />
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from '@/components/layouts/NavBar.vue'
import FooterPage from '@/components/layouts/footer-page'
export default {
  components: { Navbar, FooterPage },
}
</script>

<style scoped>
div.about-pmgc h4.center-align.white-text.animated.slideInLeft {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  padding: 1rem;
  top: 50% !important;
  left: 0px;
  right: 0px;
  font-weight: bold;
  text-transform: uppercase;
}
div.about-pmgc .card-panel.grey-text.text-darken-3 {
  padding: 0.5rem 2.4rem !important;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #263238;
  position: absolute;
  left: -20px;
  top: 10px;
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
  padding: 1rem;
  width: 100%;
}

.collection-item.avatar .circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
}
.collection-item .title {
  font-weight: bold;
  font-size: 18px;
}
.collection-item p {
  margin: 5px 0 0;
}
.card-panel {
  padding: 1.5rem;
  border-radius: 10px;
}
.header {
  margin-bottom: 30px;
}


div.about-pmgc .arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #263238;
  position: absolute;
  left: 18.5%;
}

/* Center the mission header */
.ourMission-content .header {
  margin-bottom: 30px;
}

/* Style the collection items */
.collection-item.avatar .circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
}

/* Adjust title and text */
.collection-item .title {
  font-weight: bold;
  font-size: 18px;
}

.collection-item p {
  margin: 5px 0 0;
}
</style>
