<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
div select {
  display: block;
}
input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:active:not([readonly]),
input:not([type]):active:not([readonly]),
input[type='text']:not(.browser-default):active:not([readonly]),
input[type='password']:not(.browser-default):active:not([readonly]),
input[type='email']:not(.browser-default):active:not([readonly]),
input[type='url']:not(.browser-default):active:not([readonly]),
input[type='time']:not(.browser-default):active:not([readonly]),
input[type='date']:not(.browser-default):active:not([readonly]),
input[type='datetime']:not(.browser-default):active:not([readonly]),
input[type='datetime-local']:not(.browser-default):active:not([readonly]),
input[type='tel']:not(.browser-default):active:not([readonly]),
input[type='number']:not(.browser-default):active:not([readonly]),
input[type='search']:not(.browser-default):active:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #2691f3;
  box-shadow: 0 1px 0 0 #2691f3;
}

input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly])
  + label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #2691f3;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  /* text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}

.bolder {
  font-weight: 600 !important;
}

.bold {
  font-weight: bold;
}
</style>
