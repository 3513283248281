<template>
  <div class="navbar">
    <nav class="blue-grey darken-2" style="height: 67px">
      <div class="nav-wrapper">
        <!-- Brand Logo -->
        <a href="/" class="brand-logo left">
          <img
            :src="require('@/assets/logowhite.png')"
            alt="PMGC"
            width="50px"
            height="56px"
            class="responsive-img"
          />
        </a>
        <!-- Mobile Menu Trigger -->
        <a href="#" data-target="slide-out-mobile" class="sidenav-trigger right">
          <i class="icon ion-navicon x3"></i>
        </a>
        <!-- Desktop Navigation Links -->
        <ul class="right hide-on-med-and-down" style="padding-right: 20px;">
          <li><router-link to="/" class="btn blue">Home</router-link></li>
          <li>
            <router-link to="/PRC2025" class="btn red animated pulse infinite waves-ripple">
              PRC2025
            </router-link>
          </li>
          <li><router-link to="/programs" class="btn blue">Our Programs</router-link></li>
          <li><router-link to="/about" class="btn blue">About Us</router-link></li>
          <li><router-link to="/contact" class="btn blue">Contact Us</router-link></li>
        </ul>
      </div>
    </nav>
    <!-- Mobile Navigation Links -->
    <ul id="slide-out-mobile" class="sidenav blue-text">
      <li><a @click="navigateTo('/')">Home</a></li>
      <li><div class="divider"></div></li>
      <li>
        <router-link to="/PRC2025">
          PRC2025
        </router-link>
      </li>
      <li><div class="divider"></div></li>
      <li><a @click="navigateTo('/programs')">Our Programs</a></li>
      <li><div class="divider"></div></li>
      <li><a @click="navigateTo('/about')">About Us</a></li>
      <li><div class="divider"></div></li>
      <li><a @click="navigateTo('/contact')">Contact Us</a></li>
    </ul>
  </div>
</template>

<script>
import M from 'materialize-css';

export default {
  mounted() {
    // Initialize the sidenav for mobile view
    M.Sidenav.init(document.querySelectorAll('.sidenav'), {
      edge: 'right',
      draggable: true,
    });
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
/* Adjustments for the navigation bar */
.nav-wrapper {
  padding-left: 2.9rem;
}

.nav-wrapper .brand-logo img {
  margin-top: 5px;
}

.nav-wrapper ul.right > li > a.btn {
  font-size: 0.7rem;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
}

.sidenav a {
  font-size: larger !important;
  color: #2691f3 !important;
}

.sidenav .divider {
  margin: 0;
}
</style>
