import { createApp } from 'vue'
import App from './App'
import router from './router'
// import { sync } from 'vuex-router-sync';
import 'materialize-css/dist/css/materialize.min.css'
import 'materialize-css/dist/js/materialize.min.js'
import { IonIcon } from '@ionic/vue';





import $ from "jquery"

import store from './store/store'
import Vuex from 'vuex'
// import vuetify from './plugins/vuetify' // Ensure Vuetify plugin is imported

const app = createApp(App)

// createApp(App).use(store).use(router).mount('#app');
window.$ = $ // Makes jQuery available globally
app.use(Vuex)
app.use(store) // Use the store if applicable
app.use(router) // Use the router
// app.use(vuetify)
app.component('ion-icon', IonIcon);
app.mount('#app') // Mount the app to the DOM
app.config.productionTip = false
// sync(store, router); // Comment out if not using vuex-router-sync
