<template>
    <div class="members-of-pmgc" id="pmgcMembers">
      <navbar/>
      <div class="allMemberSlides">
         <div class="slider" id="sliderMembers">
          <ul class="slides ">
            <li>
              <img :src="require(`@/assets/members/president22.jpg`)" alt="PMGC President Mr. Agboola"> <!-- random image -->
              <div class="caption left-align">
                <h4>The Founder</h4>
                <h5 class="light grey-text text-lighten-3">Mr. Abdulkadri G. Agboola</h5>
              </div>
            </li>
            <li>
              <img :src="require(`@/assets/members/makinde.jpg`)" alt="PMGC Member. Dr. Makinde"> <!-- random image -->
              <div class="caption right-align">
                <h4>Head of Educational Services and consultancy</h4>
                <h5 class="light grey-text text-lighten-3">Prof. Deborah O. Makinde</h5>
              </div>
            </li>
            <li>
              <img :src="require(`@/assets/members/jaiyeola.jpg`)" alt="PMGC Member, Dr. Jaiyeola"> <!-- random image -->
              <div class="caption left-align">
                <h4>Head of Human Resources Management</h4>
                <h5 class="light grey-text text-lighten-3">Prof. Temitope G. Jaiyeola</h5>
              </div>
            </li>
            <li>
              <img   :src="require(`@/assets/members/femiUk.jpg`)"  alt="Omotosho Femi UK, PMGC Member">
              <div class="caption right-align">
                <h4>International Legal Unit Lead</h4>
                <h5 class="light grey-text text-lighten-3">Mr. Femi Omotosho (UK)</h5>
              </div>
            </li>
            <li>
              <img  :src="require(`@/assets/members/ajibson.jpg`)" alt="Head of logistics"/> <!-- random image -->
              <div class="caption left-align">
                <h4>Head of Logistics Department</h4>
                <h5 class="light grey-text text-lighten-3">Mr. Samson T. Ajibola</h5>
              </div>
            </li>
            <li>
              <img :src="require(`@/assets/members/mr.Kenny.png`)"> <!-- random image -->
              <div class="caption right-align">
                <h4>Finance Director/SH</h4>
                <h5 class="light grey-text text-lighten-3">Mr. Kehinde Adeshina</h5>
              </div>
            </li>
            <li>
              <img :src="require(`@/assets/members/ladygospel.jpeg`)"> <!-- random image -->
              <div class="caption right-align">
                <h4>Assistance Finance Director</h4>
                <h5 class="light grey-text text-lighten-3">Ms Adewale Gospel Oluwabukunmi </h5>
              </div>
            </li>
            <li>
              <img :src="require(`@/assets/members/joy.jpeg`)"> <!-- random image -->
              <div class="caption right-align">
                <h4>HOD PRC State Coordinator</h4>
                <h5 class="light grey-text text-lighten-3">Ms Ibejekwe Ogechukwu Joy</h5>
              </div>
            </li>
  
            <!-- <li>
              <img src="../../assets/members/taiwoimg.png">
              <div class="caption left-align">
                <h4>Member</h4>
                <h5 class="light grey-text text-lighten-3">Mr. Taiwo Adeshina</h5>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="members">
          <h3 class="text-center center-align" id="teamMembers"><i class="icon ion-android-contacts"></i> &nbsp;PMGC Members</h3>
          <div class="col s10 m6 l3 offset-s1">
            <div class="card">
              <div class="card-image">
                <img :src="require(`@/assets/members/makinde.jpg`)" class="responsive-img" style="height: 307px">
                <span class="card-title">Prof. Deobrah Makinde O.</span>
              </div>
              <div class="card-content">
                <span class="black-text bold">Director of Education/SH</span><br><br>
                <p>Accomplished educationist & complex analyst, with extensive teaching experience across all educational levels, currently serving as a Professor at Obafemi Awolowo University, where she continues to inspire and educate future generations.</p><br>
              </div>
              
            </div>
          </div>
          <div class="col s10 m6 l3 offset-s1">
            <div class="card">
              <div class="card-image">
                <img :src="require(`@/assets/members/jaiyeola.jpg`)" class="responsive-img" style="height: 307px">
                <span class="card-title">Prof. Jaiyeola Temitope G.</span>
              </div>
              <div class="card-content">
                <span class="black-text bold">Director of Human Resources/SH</span><br><br>
                <p>
                  A renowned Mathematician and academic leader, bringing analytical expertise and strategic optimization skills to HR management driving informed decision-making and growth. Proven track record of achievement, with prestigious awards and publications.
                </p>
              </div>
              
            </div>
          </div>
          <div class="col s10 m6 l3 offset-s1">
            <div class="card">
              <div class="card-image">
                <img :src="require(`@/assets/members/president22.jpg`)" class="responsive-img" style="height: 307px">
                <span class="card-title">Mr. Abdulkadri Agboola G.</span>
              </div>
              <div class="card-content">
                <span class="black-text bold">President/CEO</span><br><br>
                <p>Renewable Energy Expert | Business Strategist | Coach
                    Dedicated to sustainable development, empowering youths, and driving positive change. Proven expertise in renewable energy, business growth, and social responsibility.</p><br>
              </div>
            </div>
          </div>
          <div class="col s10 m6 l3 offset-s1">
            <div class="card">
              <div class="card-image">
                <img :src="require(`@/assets/members/ajibson.jpg`)" class="responsive-img" style="height: 307px">
                <span class="card-title">Mr. Ajibola Samson T.</span>
              </div>
              <div class="card-content">
                <span class="black-text bold">Head of Department, Logistics</span><br><br>
                <p>
                  Experienced logistics professional with a degree from University of Abuja and certifications in data analysis. His expertise in data-driven insights, problem-solving, and leadership skills qualify him to head the Logistics Department at PMGC.<br><br>
                </p>
              </div>
              
            </div>
          </div>
          <div class="col s10 m6 l3 offset-s1">
            <div class="card">
              <div class="card-image">
                <img :src="require(`@/assets/members/mr.Kenny.png`)" class="responsive-img" style="height: 307px">
                <span class="card-title">Mr. Kehinde Adeshina</span>
              </div>
              <div class="card-content">
                <span class="black-text bold">Finance Director/SH</span><br><br>
                <p>
                  Results-driven Finance Director with expertise in project management, budget planning, and financial supervision.Combining financial acumen with a background in Physiology, bringing a unique analytical perspective to financial leadership.
                </p>
              </div>
              
            </div>
          </div>
          <div class="col s10 m6 l3 offset-s1">
            <div class="card">
              <div class="card-image">
                <img :src="require(`@/assets/members/ladygospel.jpeg`)" class="responsive-img" style="height: 307px">
                <span class="card-title">Ms Adewale Gospel Oluwabukunmi</span>
              </div>
              <div class="card-content">
                <span class="black-text bold">Assistance Finance Director</span><br><br>
                <p>Trusted Assistant to the Finance Director at Platinum Minds Global Company. Accomplished Accountant with a history of enhancing growth and efficiency. Skilled in financial reporting, account management, problem-solving, and effective communication, with a strong focus on HSE standards.</p>
              </div>
              
            </div>
          </div>
          <div class="col s10 m6 l3 offset-s1">
            <div class="card">
              <div class="card-image">
                <img :src="require(`@/assets/members/joy.jpeg`)" class="responsive-img" style="height: 307px">
                <span class="card-title">Ms Ibejekwe Ogechukwu Joy</span>
              </div>
              <div class="card-content">
                <span class="black-text bold">HOD PRC State Coordinator</span><br><br>
                <p>
                  Seasoned Meteorologist and Academic Tutor with deep expertise in climate change mitigation, adaptation, and environmental sustainability. Committed to education, research, and advancing climate resilience. Proven track record of empowering youths & fostering community resilience.
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <footer-page/>
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/layouts/NavBar'
  import FooterPage from '@/components/layouts/footer-page'
  import M from 'materialize-css'
  import $ from 'jquery'
  export default {
    components: {Navbar, FooterPage},
    data () {
      return {}
    },
    mounted () {
      $('#pmgcMembers>div.row>div>div>div>div.card-image>img').addClass('materialboxed')
      var elem = document.querySelector('#sliderMembers')
      // eslint-disable-next-line
      var instance = new M.Slider(elem, {
        indicators: false
      })
  
      var el = document.querySelector('.materialboxed')
      // eslint-disable-next-line
      var instance = new M.Materialbox(el, {
        inDuration: 300,
        outDuration: 375
      })
    }
  }
  </script>
  <style>
  div.members-of-pmgc .slider .slides li img, div.achievements #slideAchievements > ul.slides > li > img {
      height: 100%;
      width: 100%;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      filter: brightness(.7);
  }
  </style>
  